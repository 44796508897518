import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/:org',
    name: 'flight-search',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/flights/Search.vue')
  },
  {
    path: '/:org/:departure/:destination/:from/:to?/:adt/:chd/:inf/:way/:class?/:flex?',
    name: 'flight-list',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/flights/List.vue'),
    props: (route) => ({
        ...route.params
    })
  },
  {
    path: '/:org/price/:searchKey',
    name: 'flight-choose',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/flights/Choose.vue'),
    props: (route) => ({
        ...route.params
    })
  },
  {
    path: '/:org/book/:searchKey',
    name: 'flight-book',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/flights/Book.vue'),
    props: (route) => ({
        ...route.params
    })
  },
  { 
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "about" */ '../views/not-found.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes
})

export default router
