import { createStore } from 'vuex'

export default createStore({
  state: {
    flightData: null,
    flightSearchData: {}
  },
  mutations: {
    setFlightData(state, data) {
      state.flightData = data
    },
    setFlightSearchData(state, data) {
      state.flightSearchData = data
    }
  },
  actions: {
    setFlightData({ commit }, data) {
      commit('setFlightData', data)
    },
    setFlightSearchData({ commit }, data) {
      commit('setFlightSearchData', data)
    }
  },
  getters: {
    getFlightData(state) {
      return state.flightData
    },
    getFlightSearchData(state) {
      return state.flightSearchData
    }
  }
})