import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'

import store from './store'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { fas } from '@fortawesome/free-solid-svg-icons'

import "multi-range-slider-vue/MultiRangeSliderBlack.css"
import "multi-range-slider-vue/MultiRangeSliderBarOnly.css"

library.add(fas)

import styles from '@/assets/css/main.scss'

const app = createApp(App)
.component('font-awesome-icon', FontAwesomeIcon)
.use(
    router,
    styles,
    Vuex
)

app.use(store)

app.config.globalProperties.apiUrl = 'https://api-old.flights.travixx.com/api/'
app.config.globalProperties.publicPath = 'https://api-old.flights.travixx.com/'

/*
app.config.globalProperties.apiUrl = 'http://127.0.0.1:8005/api/'
app.config.globalProperties.publicPath = 'http://127.0.0.1:8005/'
*/

app.mount('#app')